<script setup>
import { useUserStore } from "@/state";
import { firestoreStore, unitPin } from "@/utils";
import { useCollection } from "vuefire";
import { toRefs, ref, watch } from "vue";
import { plansRef } from "@/firebase";
import { useUnitPinConfiguration, useAbbreviatedPrice } from "@/composables";
import Plan from "./Plan.vue";

const { user } = useUserStore();
const {
  state: { globalSettings },
} = firestoreStore();
const { data: plans } = useCollection(plansRef);

const props = defineProps({
  units: {
    type: Array,
    required: true,
  },
  filteredUnitsIds: {
    type: Array,
    required: true,
  },
});
const { units, filteredUnitsIds } = toRefs(props);

const { unitPinConfigurationAvailable, unitPinConfigurationUnavailable } =
  useUnitPinConfiguration();

const interactiveImages = ref([]);
watch(
  [
    plans,
    units,
    filteredUnitsIds,
    unitPinConfigurationAvailable,
    unitPinConfigurationUnavailable,
  ],
  () => {
    if (
      units.value.length === 0 ||
      plans.value.length === 0 ||
      unitPinConfigurationAvailable.value === undefined ||
      unitPinConfigurationUnavailable.value === undefined
    )
      return;

    interactiveImages.value = [
      ...plans.value.map((plan) => {
        let pins = [];
        if (plan.pins?.length > 0) {
          plan.pins.forEach(({ xCoord: x, yCoord: y, unit }, index) => {
            const assignedUnit = units.value.find(({ id }) => id === unit);
            if (assignedUnit === undefined) return;

            const { id, name, status, price } = assignedUnit;
            let item = {
              name,
              scaleOnHover: {
                scaleTo: 1.4,
                duration: 100,
              },
              classList: !filteredUnitsIds.value.includes(id)
                ? "opacity-50"
                : undefined,
            };

            let priceAfterDiscount = price;
            if (
              globalSettings.value.allowDiscountNoSurvey ||
              user.answeredDiscountSurvey
            ) {
              const discountAmount = globalSettings.value.globalDiscountAmount;
              if (globalSettings.value.globalDiscountType === "amount") {
                priceAfterDiscount -= discountAmount;
              } else {
                priceAfterDiscount -= price * (discountAmount / 100);
              }
            }

            if (status === "available") {
              item = {
                ...item,
                ...unitPinConfigurationAvailable.value,
                price: useAbbreviatedPrice(priceAfterDiscount),
              };
            } else {
              item = {
                ...item,
                ...unitPinConfigurationUnavailable.value,
                price: "SOLD",
              };
            }

            pins.push({
              index,
              unit,
              x,
              y,
              item,
              itemCreator: () => unitPin(item),
              coordinateAdjuster: (coordinate, node) =>
                coordinate - node.getBBox().width / 2,
            });
          });
        }

        return {
          plan,
          pins,
        };
      }),
    ].sort((a, b) => a.plan.order - b.plan.order);
  },
  { immediate: true, deep: true },
);

const emit = defineEmits({
  clickPin: ({ unit }) => typeof unit === "string",
});
const clickPin = ({ unit }) => {
  emit("clickPin", { unit });
};
</script>
<template>
  <div class="sm:px-60 px-2 pb-7 flex flex-col items-center gap-6">
    <div
      v-for="{ plan, pins } in interactiveImages"
      v-bind:key="plan.id"
      class="w-full flex flex-col items-center"
    >
      <h1 class="mb-1">{{ plan.name }}</h1>
      <p v-if="plan.description" class="mb-3 max-w-[1024px] justify-center">
        {{ plan.description }}
      </p>
      <Plan :src="plan.image.full" :pins="pins" @clickPin="clickPin" />
    </div>
  </div>
</template>

<style scoped>
.justify-center {
  text-align: justify;
  text-align-last: center;
}
</style>
